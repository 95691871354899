@import '/src/variables.scss';
@import '/src/mixins.scss';

.button {
  height: 48px;
  font-size: 16px;
  line-height: 48px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: $accent-color;
  border-radius: 6px;
  border: none;
  padding: 0 24px;
  cursor: pointer;
  transition: 0.15s;

  @include hover() {
    background: $accent-color-hover;
  }

  &:focus {
    outline: none;
    background: $accent-color-hover;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;

    @include hover() {
      background: $accent-color;
    }
  }

  &.outlined {
    background: #fff;
    border: 1px solid $accent-color;
    color: $accent-color;
  }
}
