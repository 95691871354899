@font-face {
  font-family: 'Avenir Next';
  src:
    local('Avenir Next Regular'),
    local('Avenir-Next-Regular'),
    url('AvenirNextCyr-Regular.woff2') format('woff2'),
    url('AvenirNextCyr-Regular.woff') format('woff'),
    url('AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src:
    local('Avenir Next Medium'),
    local('Avenir-Next-Medium'),
    url('AvenirNextCyr-Medium.woff2') format('woff2'),
    url('AvenirNextCyr-Medium.woff') format('woff'),
    url('AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src:
    local('Avenir Next Demi'),
    local('Avenir-Next-Demi'),
    url('AvenirNextCyr-Demi.woff2') format('woff2'),
    url('AvenirNextCyr-Demi.woff') format('woff'),
    url('AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src:
    local('Avenir Next Bold'),
    local('Avenir-Next-Bold'),
    url('AvenirNextCyr-Bold.woff2') format('woff2'),
    url('AvenirNextCyr-Bold.woff') format('woff'),
    url('AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
