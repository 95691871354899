@import '/src/variables.scss';
@import '/src/mixins.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.nav {
  display: flex;
  gap: 40px;

  &Link {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: $accent-color;
    transition: 0.15s;

    @include hover() {
      color: $accent-color-hover;
    }
  }
}

.btn {
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 15px;
}
