.container {
  min-height: 100vh;
  max-width: 1380px;
  padding: 30px 30px 75px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
}
