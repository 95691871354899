@mixin hover() {
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin adaptive($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}
