@import '~normalize.css';
@import 'variables';
@import 'assets/fonts/fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $text-color;
  overflow-x: hidden;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.page-error {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.ck.ck-toolbar {
  border-color: $text-light-color !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border-color: $text-light-color !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  box-shadow: none !important;
  min-height: 100px !important;
  padding: 0 16px !important;

  p {
    font-size: 16px !important;
    color: $text-color !important;
  }
}

.ck .ck-placeholder {
  font-size: 16px !important;
  color: $text-light-color !important;
}

a.active {
  border-bottom: 1px solid $accent-color;
}
