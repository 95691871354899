$laptop: 1024px;
$tablet: 834px;
$mobile: 480px;

$accent-color: #4481c4;
$accent-color-hover: #4079b6;
$secondary-color: #f5f7fa;
$text-color: #292c2f;
$text-light-color: #9e9e9e;
$error-color: #f33030;
