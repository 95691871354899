@import '/src/variables.scss';
@import '/src/mixins.scss';

.wrapper {
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  width: 100%;
  max-width: 520px;
  position: relative;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 48px;
}

.logoWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 20px 30px 0px #00000059;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
