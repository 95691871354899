.confirmPopup {
  &Wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(58, 63, 68, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    opacity: 1;
    animation: fadeIn 0.2s linear;

    &.animate {
      opacity: 0;
      animation: fadeOut 0.2s linear;
    }
  }

  &Block {
    width: 500px;
    padding: 30px;
    background: #ffffff;
    border-radius: 6px;
    animation: slideIn 0.2s linear;

    &.animate {
      animation: slideOut 0.2s linear;
    }
  }

  &Title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &Text {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  &Btns {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &Btn {
    min-width: 180px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes slideIn {
    0% {
      transform: translate3d(0, 100px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes slideOut {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, 100px, 0);
    }
  }
}
